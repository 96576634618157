<template>
    <div class="paragraph-wrapper d-flex flex-wrap flex-md-nowrap flex-grow-1 my-3" :class="{ 'col': type === 'col', 'figure': $t(`${path}.type`) === 'figure', [$t(`${path}.bgcolor`)]: true }">
      <template v-if="type === 'points'">
        <figure class="mx-auto" v-if="$t(`${path}.type`) === 'figure'">
            <img class="mx-auto d-block" alt="" :src="`${$t('baseurl')}img/${$t(`${path}.src`)}`" loading="lazy" />
            <figcaption class="text-center mx-auto fc-g7 p-2">
              <p v-html="$t(`${path}.caption`)" />
            </figcaption>
        </figure>
        <b-row v-else class="mx-0">
          <b-col md="1" :order-md="(index % 2) ? 3 : 0">
		  </b-col>
          <b-col md="2" :order-md="(index % 2) ? 0 : 2">
            <i :class="`fas fa fa-${$t(`${path}.icon`)} ${$t(`${path}.icon_color`)} fa-4x mr-md-4 fa-flex-center h-100 align-items-center`"></i>
          </b-col>
          <b-col md="9">
            <h4 v-html="$t(`${path}.title`)" />
            <p v-html="$t(`${path}.body`)" />
          </b-col>
        </b-row>
      </template>
      <div v-else v-html="$t(path)" />
    </div>
</template>
<script>
export default {
    props: ['path', 'type', 'index']
}
</script>
<style lang="scss">
.paragraph-wrapper {
  figure img {
    max-width: 100%;
  }


  @media (min-width: 650px) {
    &:not(.figure) {
      &:nth-child(even) {
        margin: 0 0 0 14rem;
      }
      &:nth-child(odd) {
        margin: 0 14rem 0 0;
      }
    }
  }
}
</style>
