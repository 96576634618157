<template>
    <div class="lokas my-3 px-md-0 container">
        <h3 v-html="$t(`${path}.title`)" :id="pathToId(path)" />
        <b-row class="pb-5 align-items-center">
            <b-col lg="5" class="mt-4" order="2" order-lg="1">
                <div v-html="intro" />
                <p class="actions">
                    <a class="btn btn-lg btn-primary" :href="$t(`${path}.btn1.link`)" target="_blank" rel="noopener">
                        {{ $t(`${path}.btn1.label`) }}
                    </a>
                    <br />
                    <a class="" :href="$t(`${path}.btn2.link`)" target="_blank" rel="noopener">
                        {{ $t(`${path}.btn2.label`) }}
                    </a>
                </p>

            </b-col>
            <b-col lg="7" order="1" order-lg="2">
                <img loading="lazy" :src="`${$t('baseurl')}img/lokas_screens.png`" alt="" width="700" />
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    props: ['path'],
    computed: {
        intro() {
            return this.$t(`${this.path}.intro`);
        }
    }
}
</script>
<style lang="scss">
.lokas {
    p.actions {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
        gap: 0.5rem;
        padding-top: 2rem;

        a:not(.btn) {
            background-image: linear-gradient(var(--f-g9), var(--f-g9)), linear-gradient(var(--f-g5), var(--f-g5));
        }
    }

    img {
        background-color: #fff;
        max-width: 100%;
        height: auto;
    }

    h3 {
        padding-bottom: 1rem;
    }
}
</style>