<template>
    <Part section="risks_second" :chapters="chapters" :intro="intro" />
</template>
<script>
import Part from './Part.vue';
export default {
    name: 'Risks2',
    components: {
        Part
    },
    data() {
        return {
            intro: true,
            chapters: {
                work: {
                    title: true,
                    type: 'points'
                },
                culture: {
                    title: true,
                    type: 'prez',
                    figure: true,
                },
                political: {
                    intro: false,
                    type: 'two',
                    quote: true,
                    title: true,
                }
            }
        }
    }
}
</script>
