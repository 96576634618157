export function pathToId(path) {
  return path
    .replace('.chapters.', '_') // Enlève la section chapters
    .replace(/\./g, '_') // Remplace les points par des underscores
    .replace(/\[|\]/g, '_') // Remplace les crochets "[" et "]" par des underscores
    .replace(/_+$/, ''); // Supprime les underscores à la fin (si nécessaires)
}

export default {
  install(Vue) {
    Object.defineProperty(Vue.prototype, 'pathToId', {
      value: pathToId,
    });
  },
};
