<template>
    <div class="py-3 py-md-1 d-flex article flex-wrap flex-md-nowrap justify-content-center justify-content-md-start w-100">
        <a :href="article.url" class="d-flex align-items-center col col-md-2 mx-0 px-0 pl-3" aria-hidden="true" tabindex="-1" target="_blank" rel="noopener">
            <img v-if="article.img !== 'https://curation.framamia.org/'" alt="" class="rounded" :src="article.img"
                style="width: 100%; height: 80px; object-fit: cover" loading="lazy" />
            <p v-else class="m-auto">
                <i class="fa fa-2x fa-newspaper fa-flex-center fc-g6" />
            </p>
            <span class="sr-only"></span>
        </a>
        <div class="content-wrapper">
            <div class="mb-1 d-flex flex-wrap">
                <p class="mr-2 mb-0">
                    <i class="fas fa-calendar" />
                    <time :datetime="article.date">
                        {{ article.date.split('T')[0] }}
                    </time>
                </p>
                <p class="mr-2 mb-0">
                    <i class="fas fa-tag" />
                    <a :href="`https://curation.framamia.org/?searchtags=${tag.link}`" class="mx-1 py-1"
                        target="_blank" rel="noopener" v-for="tag in article.categories" :key="tag.link">
                        {{ tag.label }}
                    </a>
                </p>
            </div>
            <a :href="article.url" target="_blank" rel="noopener" v-html="article.title" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'NewsItem',
    props: {
        article: {
            required: true,
            type: Object
        }
    }
}
</script>