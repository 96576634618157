<template>
    <b-row>
        <Chart :path="`${path}.cols[0]`" type="row" />
        <b-col v-for="i in nbCols - 1" :key="i" class="align-content-stretch">
            <Chart :path="`${path}.cols[${i}]`" />
        </b-col>
    </b-row>
</template>

<script>
import Chart from './Chart.vue';

export default {
    components: {
        Chart,
    },
    props: ['path'],
    computed: {
        nbCols() {
            return this.$t(`${this.path}.cols`).length;
        }
    }
}
</script>