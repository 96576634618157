<template>
    <b-row class="my-5 mx-0">
        <b-col :md="type === 'row' ? 4 : 12">
            <h4 :id="pathToId(path)">
                <i :class="`fas fa-${$t(`${path}.icon`)}`"></i>
                <span v-html="$t(`${path}.title`)" />
            </h4>
            <p class="mb-4" v-html="$t(`${path}.body`)" />
        </b-col>
        <b-col :md="type === 'row' ? 8 : 12" class="chart-container">
            <Pie v-if="$t(`${path}.chart.type`) === 'pie'" :chart-options="chartOptions" :chart-data="chartData" :chart-id="`chart-${pathToId(path)}`" />
            <Bar v-else :chart-options="chartOptions" :chart-data="chartData" :width="300" :height="300" :chart-id="`chart-${pathToId(path)}`" />
        </b-col>
    </b-row>
</template>

<script>
import { Bar, Pie } from 'vue-chartjs/legacy'
import he from 'he';

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ArcElement
} from 'chart.js'

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = "'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

export default {
    name: 'BarChart',
    components: {
        Bar,
        Pie,
    },
    props: {
        path: {
            type: String,
        },
        type: {
            type: String,
            default: 'col'
        }
    },
    computed: {
        bar() {
            return this.$t(`${this.path}.chart.data`);
        },
        labels() {
            return this.bar?.map(chart => chart.label);
        },
        numbers() {
            return this.bar?.map(chart => chart.number);
        },
        backgroundColor() {
            return this.bar?.map(chart => chart.bgcolor ?? '#725794');
        },
        title() {
            return this.$t(`${this.path}.chart.title`);
        },
        chartData() {
            return {
                labels: this.labels,
                datasets: [
                    {
                        label: he.decode(this.$t(`${this.path}.chart.label`)),
                        backgroundColor: this.backgroundColor,
                        data: this.numbers
                    }
                ]
            }
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: this.$t(`${this.path}.chart.indexAxis`) === 'y' ? 'y' : 'x',
                plugins: {
                    legend: {
                        display: this.$t(`${this.path}.chart.show_legend`) === "yes",
                    },
                    title: {
                        display: true,
                        text: Array.isArray(this.title) ? this.title.map(t => he.decode(t)) : he.decode(this.title),
                    }
                }
            }
        }
    }
}
</script>
<style lang="scss">
.chart-container {
    max-width: calc(100% - 2rem);
}
</style>