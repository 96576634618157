<template>
    <div class="two-col">
        <div class="two-col-wrapper">
            <div v-for="i in nbCards" :key="i">
                <figure v-if="$t(`${path}.cards[${i - 1}].type`) === 'figure'">
                    <img alt="" :src="`${$t('baseurl')}img/${$t(`${path}.cards[${i - 1}].src`)}`" loading="lazy" />
                    <figcaption class="fc-g7 text-center mx-auto p-2">
                        <p v-html="$t(`${path}.cards[${i - 1}].caption`)" />
                    </figcaption>
                </figure>
                <template v-else>
                    <h3 v-if="path === 'preamble.chapters.default'" v-html="$t(`${path}.cards[${i - 1}].title`)" :id="pathToId(`${path}.cards[${i - 1}]`)" />
                    <h4 v-else v-html="$t(`${path}.cards[${i - 1}].title`)" :id="pathToId(`${path}.cards[${i - 1}]`)" />
                    <div v-html="$t(`${path}.cards[${i - 1}].body`)" />
                </template>
            </div>
        </div>
        <blockquote v-if="quote" v-html="$t(`${path}.quote`)" />
    </div>
</template>
<script>
export default {
    props: ['path', 'quote'],
    computed: {
        nbCards() {
            return this.$t(`${this.path}.cards`).length;
        }
    }
}
</script>
<style lang="scss">
.two-col-wrapper {
    margin: 2rem auto;

    > div {
        padding: 1rem 0;
    }

    @media (min-width: 576px) {
        column-count: 2;
        gap: 3rem;
    }

    figure {
        img {
            max-width: 300px;
            display: block;
            margin: auto;
        }
    }
}

.two-col blockquote {
    font-size: 1.5rem;
}
</style>