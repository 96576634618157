<template>
    <Part section="preamble" :chapters="chapters" />
</template>
<script>
import Part from './Part.vue';
export default {
    name: 'Preamble',
    components: {
        Part
    },
    data() {
        return {
            chapters: {
                default: {
                    type: 'two',
                    quote: false,
                    title: false,
                },
            }
        }
    }
}
</script>
