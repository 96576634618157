<template>
    <Part section="risks_first" :chapters="chapters" :intro="intro" />
</template>
<script>
import Part from './Part.vue';
export default {
    name: 'Risks',
    components: {
        Part
    },
    data() {
        return {
            intro: true,
            chapters: {
                economy: {
                    type: 'points',
                    title: true
                },
                extractivism: {
                    intro: true,
                    type: 'two',
                    title: true,
                    quote: true,
                },
                ecocidal: {
                    title: true,
                    type: 'prez',
                    figure: true,
                },
            }
        }
    }
}
</script>
