<template>
  <section>
    <b-container class="ombre">
      <h1 v-html="$t('news.title')"></h1>

      <hr class="trait" />

      <p
        class="mt-4 mb-5"
        v-html="$t('news.subtitle', {
          link: '#newsletter',
        })"
      ></p>

      <b-row>
        <b-col
          v-if="$te('news.posts')"
          lg="8"
        >
          <article
            v-for="key in Object.keys($t('news.posts')).slice().reverse()"
            :id="key"
            :key="key"
          >
            <img
              v-if="$te(`news.posts.${key}.img`) && $t(`news.posts.${key}.img`) !== ''"
              alt=""
              class="img-fluid"
              :src="$t(`news.posts.${key}.img`)"
            />
            <div class="content-wrapper">
              <h1>
                <a
                  :href="`#${key}`"
                  v-html="$t(`news.posts.${key}.title`)"
                ></a>
              </h1>
              <p class="text-muted">
                <i class="fas fa-calendar"></i>
                <time
                  :datetime="`20${key}`"
                  v-text="`20${key}`"
                ></time>
              </p>
              <div
                class="content"
                v-html="$t(`news.posts.${key}.text`)"
              ></div>
            </div>
          </article>
        </b-col>
        <!-- Right column -->
        <b-col lg="4">
          <div class="sticky-top">
            <div class="bloc">
              <nav v-if="$te('news.posts')">
                <h3>
                  <span v-html="$t(`news.latest-posts`)"></span>
                  <a
                    :href="`${$t('baseurl')}${
                      $route.path.replace(/^\//, '').replace(/\/?$/, '/')}feed.xml`"
                    :title="this.$t('news.rss')"
                  >
                    <i class="fas fa-square-rss fa-xs fc-o6"></i>
                    <span
                      class="sr-only"
                      v-html="this.$t('news.rss')"
                    ></span>
                  </a>
                </h3>
                <ul>
                  <li
                    v-for="key in Object.keys($t('news.posts')).slice().reverse().slice(0, 5)"
                    :id="key"
                    :key="key"
                  >
                    <a
                      :href="`#${key}`"
                      v-html="$t(`news.posts.${key}.title`)"
                    ></a>
                    <time
                      class="text-muted"
                      :datetime="`20${key}`"
                      v-text="`20${key}`"
                    ></time>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  metaInfo() {
    return {
      link: [
        {
          rel: 'alternate',
          type: 'application/rss+xml',
          title: this.$t('meta.title'),
          href: `${this.$t('baseurl')}${
            this.$route.path.replace(/^\//, '').replace(/\/?$/, '/')}feed.xml`,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#news {
  article {
    margin-bottom: 2.5rem;

    p:last-of-type > img,
    img.square {
      max-width: 420px;
      margin: 1.5rem auto;
      display:block;
    }

    h2::after { content: none; }
  }

  .content-wrapper {
    padding: 2rem 1.5rem;

    img {
      margin: 1rem auto;
    }
  }

  .content {
    margin-top: 1.5rem;

    img {
      max-width: 100%;
    }
  }
}
</style>
