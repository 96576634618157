<template>
    <b-container class="chapter-wrapper mt-3" :data-chapter="pathToId(path)">
        <h3 v-if="title" v-html="$t(`${path}.title`)" :id="pathToId(path)" />
        <div v-if="intro" v-html="$t(`${path}.intro`)" />
        <TwoColumns :path="path" v-if="type === 'two'" :quote="quote" />
        <Prez :path="path" v-else-if="type === 'prez'" :figure="figure" />
        <Lokas :path="path" v-else-if="type === 'lokas'" />
        <Questions :path="path" v-else-if="type === 'question'" />
        <Charts :path="path" v-else-if="type === 'chart'" />
        <div v-else-if="type === 'img'" class="d-flex align-items-center justify-content-center">
            <img :src="`${$t('baseurl')}img/${$t(`${path}.src`)}`" :alt="escapeText($t(`${path}.alt`))" loading="lazy" />
        </div>
        <b-row v-else class="my-4 mx-0":class="{'pl-0 list-unstyled': type === 'card'}">
            <template v-if="type === 'card'">
				<MiniCard :path="`${path}.cards[${j - 1}]`" :type="type" v-for="j in nbCards" :key="j" :big="path === 'understand.chapters.families'"/>
            </template>
            <template v-else-if="['action', 'action-wrap'].includes(type)">
				<Action :path="`${path}.cards[${j - 1}]`" :type="type" v-for="j in nbCards" :key="j" :id="j"/>
            </template>
            <template v-else>
				<Paragraph :path="`${path}.cards[${i - 1}]`" :type="type" v-for="i in nbCards" :key="i" :index="i"/>
            </template>
        </b-row>
    </b-container>
</template>
<script>
import Paragraph from './Paragraph.vue';
import MiniCard from './MiniCard.vue';
import Action from './Action.vue';
import TwoColumns from './TwoColumns.vue';
import Prez from './Prez.vue';
import Lokas from './Lokas.vue';
import Questions from './Questions.vue';
import Charts from './Charts.vue';
import he from 'he';

export default {
    props: ['path', 'type', 'intro', 'quote', 'title', 'figure'],
    components: {
        MiniCard,
		Action,
        Paragraph,
        TwoColumns,
        Prez,
        Lokas,
        Questions,
        Charts
    },
    computed: {
        nbCards() {
            return this.$t(`${this.path}.cards`).length;
        },
    },
    methods: {
        escapeText(text) {
            return he.decode(text);
        }
    }
}
</script>
<style lang="scss">
.chapter-wrapper {
    blockquote {
        margin-top: 1rem;
        font-size: 1.5rem;
    }

    &[data-chapter="understand_usage"] {
        background: var(--f-g2);
        border-radius: 1.75rem;
        margin: 4rem auto;
        padding: 4rem 1rem 0;

        h3 {
            background: var(--f-o6);
            border-radius: 0.5rem;
            color: #fff !important;
            font-family: Tovari Sans,sans-serif;
            font-size: 2.4rem !important;
            font-style: normal;
            line-height: 2.5rem !important;
            padding: 1.25rem 1.75rem 0.5rem;
            text-align: center;
            text-transform: uppercase;
            transform: rotate(-2deg);
            width: fit-content;

            @media (min-width: 576px) {
                font-size: 3rem !important;
            }

            margin: -2.25rem auto 1.5rem auto !important;
        }
    }

    &[data-chapter="risks_second_work"], &[data-chapter="risks_first_economy"],&[data-chapter="understand_what"] {
        .paragraph-wrapper:not(.figure) {
            border-radius: 1.75rem;
            padding: 0.5rem;
        }
    }

    &[data-chapter="except_img"] > div > img {
        width: 500px;
        max-width: 80vw;
    }
}
.chapter-wrapper:not(:last-child) {
    margin-bottom: 5rem;
}
</style>
