<template>
    <div>
        <div class="p-1" role="tab">
            <b-button block v-b-toggle="`accordion-${questionId}`" variant="primary" class="p-3" v-html="question.q" />
        </div>
        <b-collapse :id="`accordion-${questionId}`" accordion="my-accordion" role="tabpanel">
            <b-card-body>
                <b-card-text text-tag="ul">
                    <li v-for="s in question.s">{{ s }}</li>
                </b-card-text>
            </b-card-body>
        </b-collapse>
    </div>
</template>
<script>
export default {
    props: {
        question: {
            type: Object,
            required: true
        },
        questionId: {
            type: String,
            required: true
        }
    },

}
</script>
