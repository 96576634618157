<template>
    <Part section="understand" :chapters="chapters" />
</template>
<script>
import Part from './Part.vue';
export default {
    name: 'Understand',
    components: {
        Part
    },
    data() {
        return {
            chapters: {
                what: {
                    title: true,
                    intro: true,
                    type: 'points'
                },
                how: {
                    title: true,
                    type: 'card'
                },
                usage: {
                    title: true,
                    type: 'chart'
                },
                multiple: {
                    title: true,
					intro: true,
                    type: 'action-wrap'
                },
                families: {
                    title: true,
                    type: 'card'
                }
            }
        }
    }
}
</script>
