<template>
    <section
      class="px-md-0 mx-0"
      ref="f-news"
    >
    <b-container class="f-news">
        <div class="w-100"><h2 class="mx-auto" v-html="title" /></div>
        <b-row>
          <b-col lg="6">
            <NewsItem v-for="(article, index) in articles.slice(0, 3)" :article="article" :key="index" />
          </b-col>
          <b-col lg="6">
            <NewsItem v-for="(article, index) in articles.slice(3, 6)" :article="article" :key="index" />
          </b-col>
        </b-row>
        <p class="text-center mt-4 font-weight-lighter more">
          <i class="fas fa-rss" />
          <a :href="`https://curation.framamia.org/?searchtags=~${tagsQuery}`" rel="noopener" target="_blank" v-html="$t('news.more')" />
        </p>
      </b-container>
    </section>
  </template>
  
  <script>
import NewsItem from './NewsItem.vue';

  export default {
    props: ['title', 'tags'],
    data() {
      return {
        articles: [],
        excludedCategories: ['fr', '2024', 'en']
      };
    },
    components: {
      NewsItem
    },
  
    created() {
      this.getArticles();
    },

    computed: {
      tagsQuery() {
        return this.tags.join('+~');
      }
    },
  
    methods: {
      getArticles() {
        const url = `https://curation.framamia.org/feed/atom?&searchtags=~${this.tagsQuery}`;
        fetch(url)
          .then(res => res.text())
          .then((data) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, "application/xml");
            doc.querySelectorAll("entry").forEach((item) => {
                this.articles.push({
                  url: item.querySelector('link').attributes.href.value,
                  title: item.querySelector('title').textContent,
                  desc: item.querySelector('content').textContent,
                  date: item.querySelector('published').textContent,
                  categories: Array.from(item.querySelectorAll('category')).map(item => {
                    return {label: item.attributes.label.value.replace(/_IA$/, '').replace(/_/, ' '), link: item.attributes.term.value};
                  }).filter(({ link }) => !this.excludedCategories.includes(link)),
                  img: `https://curation.framamia.org${item.querySelector('link[rel="preview"]').attributes.href.value.replace(/^tpl\/default/, '')}`,
                });
            });
          })
          .catch((err) => { console.error(err) }); // eslint-disable-line
      },
    },
  };
  </script>
  
  <style lang="scss">
  .f-news {
    h2 {
      background: var(--f-o6);
      border-radius: 0.5rem;
      color: #fff !important;
      font-family: Tovari Sans,sans-serif;
      font-size: 2rem;
      font-style: normal;
      line-height: 2.5rem !important;
      padding: 1.25rem 1.75rem 0.5rem;
      text-align: center;
      text-transform: uppercase;
      transform: rotate(-2deg);
      width: fit-content;
  
      @media (min-width: 576px) {
        font-size: 3rem;
      }

      margin: -2.25rem auto 1.5rem auto !important;
    }
  
    background: var(--f-g2);
    border-radius: 1.75rem;
    margin: 4rem auto;
    padding: 3rem .25rem 1.75rem;
  
    .content-wrapper {
      background: var(--f-g2);
      border-radius: 1.75rem;
      padding: .5rem 1.5rem;
      position: relative;
      z-index: 1;
      height: 100%;

      > a {
        background-image: none;
        color: var(--f-g9);
        font-size: 1.25rem;
        font-weight: bold;

        &:hover, &:focus {
          text-decoration: none;
        }
      }
    }

    .small {
      color: var(--f-g7) !important;
    }

  p.more {
    font-size: 1.5rem;
  }
}
  </style>
  
