<template>
    <section class="part" :data-section="pathToId(section)">
        <b-container>
            <h2 v-html="$t(`${section}.title`)" :id="pathToId(section)" />
            <div v-if="intro" v-html="$t(`${section}.intro`)" class="part-intro my-5" />
        </b-container>
        <Chapter v-for="(obj, chapter) in chapters" :key="chapter" :path="`${section}.chapters.${chapter}`" :type="obj?.type" :intro="obj?.intro" :title="obj?.title" :quote="obj.quote" :figure="obj.figure" />
    </section>
</template>
<script>
import Chapter from './Chapter.vue';
export default {
    props: ['section', 'chapters', 'intro'],
    components: {
        Chapter
    }
}
</script>
<style lang="scss">
section.part {
    padding: 3rem 0 5rem;

    .part-intro {
        font-weight: bold;
        font-size: 1.125rem;
    }
}
</style>
