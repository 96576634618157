<template>
    <div class="prez">
        <b-row class="pt-4">
            <b-col :lg="12 / nbCards('cols')" v-for="i in nbCards('cols')" :key="i">
                <h4 :id="pathToId(`${path}.cols[${i - 1}]`)">
                    <i :class="`fas fa-${$t(`${path}.cols[${i - 1}].icon`)}`"></i>
                    <span v-html="$t(`${path}.cols[${i - 1}].title`)" />
                </h4>
                <div class="mb-4" v-html="$t(`${path}.cols[${i - 1}].body`)" />
            </b-col>
        </b-row>
        <h4 v-html="$t(`${path}.cols_no_title.title`)" :id="pathToId(`${path}.cols_no_title.title`)" />
        <b-row>
            <b-col :lg="12 / nbCards('cols_no_title.cols')" v-for="j in nbCards('cols_no_title.cols')" :key="j" class="d-flex flex-column align-items-center">
                <i :class="`fas fa-2x fa-${$t(`${path}.cols_no_title.cols[${j - 1}].icon`)} fa-shape-circle fa-flex-center fa-border fc-${$t(`${path}.cols_no_title.cols[${j - 1}].color`)} fd-${$t(`${path}.cols_no_title.cols[${j - 1}].border`)} mb-3`"></i>
                <div class="mb-4" v-html="$t(`${path}.cols_no_title.cols[${j - 1}].body`)" />
            </b-col>
        </b-row>
        <figure v-if="figure" class="text-center">
            <img alt="" :src="`${$t('baseurl')}img/${$t(`${path}.figure.src`)}`" loading="lazy" class="img-fluid" />
            <figcaption class="fc-g7 text-center mx-auto p-2">
              <p v-html="$t(`${path}.figure.caption`)" />
            </figcaption>
        </figure>
    </div>
</template>
<script>
export default {
    props: {
        path: {
            type: String,
            required: true
        },
        figure: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        nbCards(type) {
            return this.$t(`${this.path}.${type}`).length;
        }
    }
}
</script>
