import Vue from 'vue';
import VueMeta from 'vue-meta';
import BootstrapVue from 'bootstrap-vue';

import is from '../commons/plugins/is';
import cookie from '../commons/plugins/cookie';
import merge from '../commons/plugins/merge';
import text from '../commons/plugins/text';
import convertId from './plugins/convertId';

import router from './router';
import i18n from './i18n';
import App from './App.vue';

import './scss/main.scss';

Vue.use(BootstrapVue);
Vue.use(VueMeta, { ssrAppId: 1 });

Vue.use(is);
Vue.use(cookie);
Vue.use(merge);
Vue.use(text);
Vue.use(convertId);

const config = process.env.CONFIG;

// Override $t() for markdown and formatting
Vue.prototype.$t = (key, locale, values) => text.$t(key, locale, values, i18n);

// Framanav
if (
  // Only if it is enabled
  config.framanav
  // in prod|preview (on prerendered files)
  && document.getElementById('app').hasAttribute('data-server-rendered')
  // and not duplicate (in case of a bug)
  && document.querySelectorAll('script[src$="nav.js"]').length < 1) {
  const nav = document.createElement('script');
  nav.src = 'https://framasoft.org/nav/nav.js';
  document.getElementsByTagName('head')[0].appendChild(nav);
}

new Vue({ // eslint-disable-line no-new
  el: '#app',
  router,
  i18n,
  render: h => h(App),
});
