<template>
    <Part section="except" :chapters="chapters" :intro="intro" />
</template>
<script>
import Part from './Part.vue';
export default {
    name: 'Except',
    components: {
        Part
    },
    data() {
        return {
            intro: true,
            chapters: {
                ethics: {
                    title: true,
                    intro: false,
					type: 'action'
                },
                img: {
                    type: 'img',
                },
                question: {
                    title: true,
                    intro: true,
                    type: 'question'
                },
                lokas: {
                    type: 'lokas'
                },
            }
        }
    }
}
</script>
