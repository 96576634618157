<template>
	<b-col class="mb-4" cols="12" md="6" lg="6" xl="4">
		<!--<b-card class="h-100 minicard" no-body>
			<b-card-body class="h-100 d-flex flex-column">
				<b-card-text v-html="$t(path)" />
			</b-card-body>
			</b-card>-->
	<button class="flip" @click="active = !active" :class="{ active }">
		<div class="front" :class="{ big }">
			<h4 class="" v-html="$t(path).title" />
		</div>
		<div class="back" :class="{ big }">
			<b-card-text v-html="$t(`${path}.body`)" />
		</div>
	</button>
	</b-col>
</template>
<script>
	export default {
		props: ['path', 'type', 'big'],
		data() {
			return {
				active: false
			};
		}
	}
</script>

<style lang="scss">
.minicard {
	border-radius: .5rem!important;
}

.flip {
	background: transparent;
	border: none;
	position: relative;
	>.front,
	>.back {
		display: block;
		transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
		transition-duration: 1s;
		transition-property: transform, opacity;
	}
	>.front {
		transform: rotateY(0deg);
	}
	>.back {
		position: absolute;
		opacity: 0;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		transform: rotateY(-180deg);
	}
	&:hover, &.active {
		>.front {
			transform: rotateY(180deg);
		}
		>.back {
			opacity: 1;
			transform: rotateY(0deg);
		}
	}
	&.flip-vertical {
		>.back {
			transform: rotateX(-180deg);
		}
		&:hover {
			>.front {
				transform: rotateX(180deg);
			}
			>.back {
				transform: rotateX(0deg);
			}
		}
	}
}

// custom
.flip {
	position: relative;
	display: inline-block;
	cursor: pointer;
	
	margin: 0.2em;
	width: 100%;
	h4 {
		color: #fff;
		text-align: center;
		font-family: Tovari Sans,sans-serif;
	}
	>.front {
		display: flex;
		align-items: center;
		justify-content: center;
	}


	>.back {
		display: block;
	}

	>.front,
	>.back {
		color: white;
		width: inherit;
		background-size: cover!important;
		background-position: center!important;
		height: 270px;
		padding: 0.25rem 0.5rem;
		@media (min-width: 1400px) {
			padding: 0.5rem 1rem;
		}
		background: var(--f-f7);
		border-radius: 10px;
		p {
			@media (min-width: 1400px) {
				line-height: 160%;
			}
			color: #555;
		}

		&.big {
			height: 465px;
		}
	}
	.back {
		background: var(--f-g1);
	}
}


</style>
