<template>
	<b-col cols="12" md="6" :lg="colsLarge" :class="{ [`action-card-${id}`]: true, wrap, last }" class="action-card">
		<h4 v-html="$t(`${path}.title`)" class="action-title"></h4>
		<div class="px-4 py-2 h-100 d-flex flex-column">
			<div class="my-2" v-html="$t(`${path}.body`)"></div>
		</div>
	</b-col>
</template>
<script>
	export default {
		props: ['path', 'id', 'type', 'last'],
		computed: {
			wrap() {
				return this.type === 'action-wrap';
			},
			colsLarge() {
				if (this.wrap && this.last) {
					return 12;
				}
				if (this.wrap) {
					return 6;
				}
				return 4;
			}
		}
	}
</script>

<style lang="scss">
	.action-title {
		margin-left: 25px;
	}
	.action-card {
		padding-top: 2em;
		list-style: none;
	}
	.action-card-1 {
		background: var(--f-g1);
		border-radius: 1rem 0em 0em 1em;
		&.wrap {
			border-radius: 1rem 0 0 0;
		}
		@media (max-width: 768px) {
			border-radius: 1rem 1rem 0 0 !important;
		}
	}
	.action-card-2 {
		background: var(--f-g2);
		&.wrap {
			border-radius: 0 1rem 0 0;
		}
		@media (max-width: 768px) {
			border-radius: 0 !important;
		}
	}
	.action-card-3 {
		background: var(--f-g3);
		border-radius: 0em 1em 1em 0em;
		&.wrap {
			border-radius: 0 0 1rem 1rem;
		}
		@media (max-width: 768px) {
			border-radius: 0 0 1rem 1rem;
		}
	}

</style>
