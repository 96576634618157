<template>
    <div class="questions my-3">
        <b-row class="accordion" role="tablist">
            <b-col md="6">
                <Question :question="question" v-for="(question, i) in $t(`${path}.questions`).slice(0, 5)" :key="i" :question-id="`1-${i}`" />
            </b-col>
            <b-col md="6">
                <Question :question="question" v-for="(question, i) in $t(`${path}.questions`).slice(5, 10)" :key="i" :question-id="`2-${i}`" />
            </b-col>
        </b-row>
    </div>
</template>
<script>
import Question from './Question.vue';

export default {
    props: ['path'],
    components: {
        Question,
    },
}
</script>
